// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-crudzoo-src-pages-404-tsx": () => import("./../../../node_modules/gatsby-crudzoo/src/pages/404.tsx" /* webpackChunkName: "component---node-modules-gatsby-crudzoo-src-pages-404-tsx" */),
  "component---node-modules-gatsby-crudzoo-src-pages-index-tsx": () => import("./../../../node_modules/gatsby-crudzoo/src/pages/index.tsx" /* webpackChunkName: "component---node-modules-gatsby-crudzoo-src-pages-index-tsx" */),
  "component---node-modules-gatsby-crudzoo-src-pages-series-tsx": () => import("./../../../node_modules/gatsby-crudzoo/src/pages/series.tsx" /* webpackChunkName: "component---node-modules-gatsby-crudzoo-src-pages-series-tsx" */),
  "component---node-modules-gatsby-crudzoo-src-templates-archive-tsx": () => import("./../../../node_modules/gatsby-crudzoo/src/templates/Archive.tsx" /* webpackChunkName: "component---node-modules-gatsby-crudzoo-src-templates-archive-tsx" */),
  "component---node-modules-gatsby-crudzoo-src-templates-blog-post-tsx": () => import("./../../../node_modules/gatsby-crudzoo/src/templates/BlogPost.tsx" /* webpackChunkName: "component---node-modules-gatsby-crudzoo-src-templates-blog-post-tsx" */),
  "component---node-modules-gatsby-crudzoo-src-templates-series-tsx": () => import("./../../../node_modules/gatsby-crudzoo/src/templates/Series.tsx" /* webpackChunkName: "component---node-modules-gatsby-crudzoo-src-templates-series-tsx" */),
  "component---node-modules-gatsby-crudzoo-src-templates-tags-tsx": () => import("./../../../node_modules/gatsby-crudzoo/src/templates/tags.tsx" /* webpackChunkName: "component---node-modules-gatsby-crudzoo-src-templates-tags-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

